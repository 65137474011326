.cms-index-index {
  .page-title-wrapper {
    display: none;
  }

  .big-spots {
    @include container-wide;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .hero,
  .usp,
  .two-column {
    @include container-wide;
  }

  .usp {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      background-image: url(../images/seo-bg.webp);
      background-repeat: repeat-y !important;
      background-position: center bottom !important;
      position: absolute;
      inset: 0 -50vw;
      z-index: -1;
      pointer-events: none;
    }
  }

  .two-column {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      background-image: url(../images/seo-bg.webp);
      background-repeat: repeat-y !important;
      background-position: center top !important;
      position: absolute;
      inset: 0 -50vw;
      z-index: -1;
      pointer-events: none;
    }
  }

  //.columns {
  //    @include container-wide;
  //}
}
