.block-search {
    position: relative;
    float: none;
    z-index: 20;
    margin: 0;

    .block-title,
    .nested,
    .label span,
    .action.search span {
        display: none;
    }

    .field.search {
        margin: 0;

        label {
            width: 32px;
            height: 32px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            margin: 0;
            //position: relative;
            //z-index: 21;
        }

        input[type="text"] {
            border: none;
            padding-right: 40px;
            background: darken($color__off-white, 4%);
            margin: 0;
        }
    }

    .label:before {
        // added styling to critical/_header.scss
    }

    .control {
        display: block;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        padding: 16px;
        overflow: hidden;
        transition: max-width .3s;
        background: darken($color__off-white, 4%);
        z-index: 21;
        width: 100vw;
        max-width: 0;

        @include media('>=tablet_portrait') {
            background: transparent;
            left: auto;
            right: 0;
            position: absolute;
            padding: 0;
            z-index: 0;
            max-width: 40px;
            top: 50%;
            transform: translateY(-50%);
        }

        @include media('>=laptop') {
            max-width: 48px;
        }


    //    border: none;
    //    margin: 0;
    //    padding: 0 16px;
    //    position: fixed;
    //    top: 0;
    //    left: 0;
    //    right: 0;
    //    transition: transform 300ms;
    //    transform: translateY(-100%);
    //    background: $color__white;
    }

    .minisearch.active .control {
        opacity: 1;
        max-width: 100vw;

        @include media('>=tablet_portrait') {
            max-width: 360px;
        }

        form > * {
            opacity: 1 !important;
            margin: 0;
            width: 100%;
        }

        //transform: translateY(0);
    }

    //input {
    //    position: static;
    //}

    .action.search {
        display: none;
        width: 32px;
        height: 32px;
        //display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 0;
        border: none;
        background: none;
        padding: 0;
        position: fixed;
        right: 20px;
        top: 20px;
        background: darken($color__off-white, 4%);
        z-index: 22;
        cursor: pointer;


        @include media('>=tablet_portrait') {
            position: absolute;
            right: 0;
            top: 0;
        }

        &:before {
            // added styling to critical/_header.scss
        }
    }

    .minisearch.active .action.search {
        display: flex;
    }

    .search-autocomplete {
        display: none;
        margin-top: -15px;
        overflow: hidden;
        position: absolute;
        z-index: 3;
    }
}

