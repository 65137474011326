/*Vendors*/
@import "vendors/normalize";
@import "vendors/include-media";
@import "vendors/jquery/mage-calendar";

@import "_breakpoints";
@import "_base/*";

// TODO: remove when Critical CSS module is setup
//@import "critical/critical-hidden";
//@import "critical/critical-cls";


@import "_global";

.page-wrapper {
  @import "_atoms/*";
  @import "_molecules/**/*";
  @import "_organisms/*";
}

@import "_templates/*";
