
/** COLORS **/

$color__green: #80BD9C;
$color__blue: #052C7A;


$color__black: #0A0C0E !default;
$color__white: #fff !default;
$color__off-white: #FAFBFC !default;

$color__form--success: #80BD9C;
$color__form--error: #ff0000;


/** BORDER RADIUS **/
$border-radius: 0 !default;
