.subpage-tabs {

    ul {
        display: flex;
        justify-content: center;
        padding: 0;
        list-style: none;
        margin: 0 0 40px !important;

        @include media('<tablet') {
            flex-direction: column;
            text-align: center !important;
        }
    }

    li {
        margin: 0;
        padding: 8px 0;

        @include media('>=tablet') {
            padding: 16px;
        }

        @include media('>=laptop') {
            padding: 32px;
        }

        a.tab-title {
            text-decoration: none;
            border-bottom: 1px solid transparent;
        }

        &.ui-state-hover,
        &.ui-state-active {
            a.tab-title {
                border-bottom: 1px solid $color__black;
            }
        }
    }


    .pagebuilder-column:not(.media-column) {
        @include media('>=tablet') {
            padding-top: 32px !important;
            padding-bottom: 32px !important;
        }
    }
}
