@at-root body {
    .modal-slide,
    .modal-popup {
        position: fixed;
        visibility: hidden;
        opacity: 0;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transition: visibility 0s .3s, opacity .3s ease;
        z-index: 900;
        overflow-y: auto;
        pointer-events: none;

        &._show {
            visibility: visible;
            opacity: 1;
            transition: opacity .3s ease;

            .modal-inner-wrap {
                transform: translateY(0);
            }
        }

        &.confirm {
            .modal-inner-wrap {
                max-width: 50rem;
            }
        }

        .modal-inner-wrap {
            position: absolute;
            left: 0;
            right: 0;
            background-color: $color__white;
            opacity: 1;
            pointer-events: auto;
            margin: 5rem auto;
            width: 75%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            height: auto;
            transform: translateY(-200%);
            transition: transform .2s ease;
            padding: 32px;
            border-radius: 4px;
        }

        .modal-header {
            display: none;
        }

        .modal-content {
            @include text;
            overflow: auto;
        }

        .modal-footer {
            display: flex;
            gap: 32px;
            justify-content: center;

            button {
                @include button;
                margin-bottom: 0;

                &.action-secondary {
                    @include button--contrast;
                }
            }
        }

    }

    .modals-overlay {
        background-color: rgba(51, 51, 51, .55);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 899;
    }
}
