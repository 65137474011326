h1,
.heading1 {
  @include heading-h1;
}

h2,
.heading2 {
  @include heading-h2;
}

h3,
.heading3 {
  @include heading-h3;
}


& {
  font-family: $text-style__text-font;
}

p, .field, .mc-field-group,
date, address, time {
  @include text;
}

p, address {
  &:last-child {
    margin-bottom: 0;
  }
}

ul, ol {
  @include text;

  li {
    margin-bottom: 24px;
  }
}


a {
  @include text-link;
}


.block-title {
    @include block-title;
}

