.catalog-category-view {
    .category-categories {
        @include container-wide;
    }

    .products-grid {
        @include container-wide;
        margin-bottom: 96px;
    }
}
