
.usp {
    .pagebuilder-column-group,
    .pagebuilder-column-line {
        justify-content: space-between;
        gap: 32px;
        padding: 16px 0;

        @include media('>=tablet_portrait') {
            padding: 24px 0;
        }
    }

    .pagebuilder-column {
        position: relative;
        background: #FFFFFF;
        box-shadow: 0 2px 16px rgba(4, 56, 102, 0.16);
        border-radius: 8px;
        width: auto !important;
        padding: 32px;
        flex: 1;
    }

    svg {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        background: rgba($color__green, 0.08);
        border-radius: 100%;
        height: 56px;
        width: 56px;
        padding: 12px;
        margin-bottom: 16px;
    }
}
