.ui-dialog {
    z-index: 20;
    padding: 16px;
    background: $color__white;
    box-shadow: 0 0 16px rgba(4, 7, 2, 0.16);
    border-radius: 4px;
    min-width: 200px;

    @include media('<tablet_portrait') {
        position: fixed;
        left: 20px;
        right: 19px;
        top: 64px;
        max-height: calc(100vh - 64px - 64px);
    }

    @include media('>=tablet_portrait') {
        position: absolute;
    }
}
