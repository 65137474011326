@at-root body {
  .callforprice-modals-wrapper {
    fieldset {
      border: none;
      display: grid;
      gap: 16px 64px;
      padding: 0;

      @include media('>=laptop') {
        grid-auto-flow: column;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
      }
    }
    fieldset:nth-child(3) {
      float: right;
    }
    textarea {
      outline: none;
      border: none;
      resize: none;
      border-radius: 8px;
      width: 100%;
      height: 120px;
      padding: 8px 16px;
    }
    input {
      outline: none;
      border: none;
      -webkit-appearance: none;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background-color: $color__off-white;
      padding: 8px 16px;
    }

    input:focus {
      outline: none;
    }

    label {
      color: $color__off-white;
      font-size: 12px;
    }
    .field:last-child {
      grid-row: span 2;
    }
  }

  .modal-slide._show .modal-inner-wrap {
    background-color: $color__green;
    width: 350px;
    height: auto;
    box-shadow: 0px 2px 16px rgba(4, 56, 102, 0.16);
    padding: 64px 32px;

    @include media('>=tablet_portrait') {
      padding: 64px 120px;
      width: 700px;
      @include media('>=laptop') {
        width: 1140px;
      }
    }
  }
  .modal-slide .modal-footer button:last-child {
    background-color: $color__blue;
    height: 40px;
  }
  .modal-slide .modal-content {
    height: 100%;
    .callforprice_modal__heading {
      @include heading-h1;
      color: $color__off-white;
      text-align: center;
    }
    .callforprice_modal__text{
      p {
        font-size: 16px;
        color: $color__off-white;
        text-align: center;
      }
    }
  }

}

