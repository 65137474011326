.contact-section {
  position: relative;

  @include media('>=tablet_portrait') {
    @include container-wide;
    margin: 64px auto;
  }

  &:before {
    content: "";
    background: url("../images/contact-v2.webp") no-repeat center $color__blue;
    background-size: cover;
    min-height: 346px;
    position: static;
    display: block;

    @include media('>=tablet_portrait') {
      position: absolute;
      inset: 80px 0;
    }
  }

  &-form-wrapper {
    @include container;
    position: relative;
    background: $color__green;
    box-shadow: 0 2px 16px rgba(4, 56, 102, 0.16);
    margin-bottom: 0;
    padding-top: 32px !important;
    padding-bottom: 40px !important;

    @include media('>=tablet_portrait') {
      padding: 32px 96px;
      margin-right: 0 !important;
      margin-left: auto !important;
      max-width: 544px;
    }
  }

  &__form {
    padding: 0;

    &:before {
      content: unset;
    }
  }

  &__header {
    margin-bottom: 16px;
    text-align: center;

    strong {
      @include heading-h1;
      color: $color__off-white;
      margin-bottom: 8px;
    }

    p {
      color: $color__white;
    }
  }

  &__fields {

    > div {
      margin-bottom: 16px;
    }

    label {
      color: $color__off-white;
    }
  }

  &__actions-toolbar {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    button {
      @include button;
      @include button--contrast;
    }
  }
}
