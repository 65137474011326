// move to molecules
.category-card {
  background: $color__white;
  box-shadow: 0 2px 16px rgba(4, 56, 102, 0.16);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__header {
    * {
      width: 100%;
      display: block;
    }

    img {
      aspect-ratio: 608 / 456;
      object-fit: cover;
    }
  }

  &__body {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__name {
    @include heading-h2;
    padding: 32px;
    text-decoration: none;
  }

  &__title {
    @include heading-h2;
    color: $color__blue;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 32px;
    margin-bottom: 0;
  }

  //&__description {
  //  @include text;
  //  padding: 0 32px;
  //  height: 72px;
  //  overflow: hidden;
  //  margin-bottom: 88px;
  //}

  &__attributes {
    //@include text;
    padding: 32px 32px 0;
    margin: 0 auto 64px;
    text-align: center;

    @include media('>=laptop') {
      margin-bottom: 88px;
    }
  }


  &__button.button {
    @include button--small;
    display: flex;
    margin: 0 !important;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 128px;

    @include media('>=laptop') {
      bottom: 32px;
    }

    &:after {
      content: "";
      position: absolute;
      inset: -50vw;
    }
  }
}