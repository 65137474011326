.two-column {
  .pagebuilder-column-group,
  .pagebuilder-column-line {
    padding: 64px 0;
    gap: 32px;

    @include media('<tablet') {
      flex-direction: column;
    }
  }

  //.pagebuilder-column:last-child {
  .pagebuilder-column:not(.two-column__image) {
    align-self: end;
    width: 100%;
    max-width: 368px;
    justify-self: center;
    margin: 32px auto;
  }

  .pagebuilder-poster-content {
    @include container-wide;
    position: relative;
    min-height: 624px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 120px;

    @include media('>=tablet') {
      padding-bottom: 0;
      align-items: flex-start;
    }

    > div {
      @include media('>=tablet') {
        width: 50%;
      }
    }
  }

  &__image {
    @include media('<tablet') {
      width: auto !important;
    }
  }

  h2 {
    @include heading-h1;
    color: $color__green;
  }

  img {
    text-align: center;
    margin: 0 auto;
    border-radius: 8px;
  }

  p a {
    margin-bottom: 0;
  }

  [data-content-type="buttons"] {
    a {
      @include button;
      margin-top: 40px;
    }
  }
}
