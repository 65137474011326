
@mixin big-spot($color: $color__green, $left: true) {
    @include container;
    padding-top: 24px;
    padding-bottom: 80px;
    overflow: hidden;
    position: relative;
    margin-bottom: 16px;

    @include media('>=tablet_portrait') {
        padding-top: 80px;
    }

    @include media('>=laptop') {
        padding: 104px 116px;

        @if ($left) {
            padding-left: 160px;
        } @else {
            padding-right: 160px;
        }
    }

    &:before {
        content: "";
        background-color: rgba($color, .8);
        position: absolute;
        inset: 0;
        z-index: 1;
    }
}

.big-spots {
    position: relative;
    margin-bottom: 32px;

    .pagebuilder-column {

        &:first-of-type {
            @include big-spot($color__green, true)
        }

        &:last-of-type {
            @include big-spot($color__blue, false);
        }

        * {
            position: relative;
            z-index: 2;
        }
    }

    .pagebuilder-poster-content {
        @include container-wide;
        position: relative;
        min-height: 624px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 120px;

        @include media('>=tablet') {
            padding-bottom: 0;
            align-items: flex-start;
        }

        > div {
            @include media('>=tablet') {
                width: 50%;
            }
        }
    }

    h2 {
        @include heading-h1;
    }

    h2, p {
        color: white !important;
    }

    a {
        @include button;
        @include button--white;
        margin-top: 40px;

        &, span {
            color: $color__black !important;
        }
    }
}
