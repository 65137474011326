
@at-root body.filter-active {
    overflow: hidden;
}

.layered-filter-block-container {
    @include container-wide;
    position: relative;
    margin-bottom: 40px;
    background: $color__blue;

    @include media('>=laptop') {
        background: none;
        margin-bottom: 144px;
    }

    @include media('<laptop') {
        .block.filter.active {
            position: fixed;
            inset: 0;
            overflow-x: hidden;
            background: $color__off-white;
            z-index: 100;

            .filter-title {
                background: $color__black;
                padding: 0 20px 0 19px;

                strong {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    outline: none;

                    &:before {
                        display: none;
                    }

                    &:after {
                        content: "";
                        background: url(../images/close.svg);
                        background-size: contain;
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            .filter-options {
                display: flex;
            }
        }
    }

    .filter-title {
        strong {
            @include text;
            text-align: center;
            line-height: 56px;
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            outline: none;

            &:before {
                content: "";
                background: url(../images/filter.svg);
                background-size: contain;
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }

        @include media('>=laptop') {
            display: none;

            strong {
                cursor: auto;
            }
        }
    }
    .filter-subtitle {
        // TODO: move to CLS
        display: none;
    }

    .filter-actions,
    .filter-current {
        display: none;
    }

    .filter-options {
        display: none;
        justify-content: space-between;
        flex-direction: column;

        @include media('>=laptop') {
            display: flex;
            flex-direction: row;
        }
    }

    .filter-options-title {
        @include text;
        cursor: pointer;
        margin-bottom: 0;

        &:after {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('data:image/svg+xml; utf8, <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.333v9.334M12.667 8 8 12.667 3.333 8" stroke="currentColor" stroke-linecap="round"/></svg>');
            transition: transform 300ms;
        }

        @include media('>=laptop') {
            flex-direction: row;
        }
    }

    .filter-options-item {
        padding: 16px 20px 16px 19px;

        @include media('>=laptop') {
            padding: 0;
        }

        &:hover,
        &.active {
            .filter-options-title {
                text-decoration: underline;
            }
        }


        &.active {
            .filter-options-title:after {
                transform: rotate(180deg);
            }
        }
    }


    .filter-options-content {

        @include media('>=laptop') {
            @include container-wide;
            position: absolute;
            top: 32px;
            left: 0;
            right: 0;
            background: #F6F5F5;
            z-index: 1;
            margin-bottom: 0;
        }
    }

    .swatch-attribute,
    .items {
        list-style: none;
        margin: 0;
        padding: 24px 0;
    }

    .swatch-option-link-layered,
    .item {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 8px 0;


        @include media('>=laptop') {
            display: inline-flex;
            margin-right: 56px;
        }

        a {
            text-decoration: none;
            cursor: pointer;
            align-items: center;
        }
    }

    #bss_show_more {
        display: none;
    }

    .count {
        display: none;
    }

    .swatch-attribute-options {
        justify-content: flex-start;
    }

    .swatch-option-link-layered {
        margin: 0;
    }

    .swatch-option {
        margin-right: 8px;
        width: 40px;
        height: 40px;
        mask-image: url('data:image/svg+xml; utf8, <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.927 11.377c-.174 0-.348 0-.518.02a15.758 15.758 0 0 0-5.711-5.226 15.833 15.833 0 0 0-15.037.026 15.757 15.757 0 0 0-5.694 5.246 6.081 6.081 0 0 0-4.95 1.478 6.024 6.024 0 0 0-.872 8.03 6.07 6.07 0 0 0 3.63 2.373 15.708 15.708 0 0 0 5.538 8.924 15.822 15.822 0 0 0 9.941 3.466 15.82 15.82 0 0 0 9.917-3.535 15.706 15.706 0 0 0 5.475-8.962 6.065 6.065 0 0 0 3.396-2.538 6.023 6.023 0 0 0-1.152-7.84 6.082 6.082 0 0 0-3.984-1.462h.021Z" fill="currentColor"/></svg>');
    }

}
