.pages {
    margin-bottom: 80px;

    .label {
        display: none !important;
    }

    .items {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .item:not(.pages-item-next):not(.pages-item-previous) {
        margin: 0 8px;

        a:hover,
        &.current span {
            background: $color__green;
        }

        a, span {
            display: flex;
            width: 24px;
            height: 24px;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 100%;
            outline: 2px solid $color__green;
            text-decoration: none;
            letter-spacing: 0;

            @include media('>=tablet_portrait') {
                width: 48px;
                height: 48px;
            }
        }
    }

    .pages-item-next,
    .pages-item-previous {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        margin: 0 8px;

        a {
            text-decoration: none;
        }
    }
}
