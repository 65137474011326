
.nav-sections {
    @include media('<tablet') {
        width: 100vw;
        position: fixed;
        left: -100vw;
        overflow: auto;
        transition: left 0.3s;
    }

    &.ninjamenus-mobile-wrapper {
        background-color: white;
        box-shadow: none;
        top: 56px;
        height: auto;
        bottom: 0;
        display: block;
    }

    .section-item-title {
        display: none;
    }
}

@at-root .nav-open .nav-sections {
    @include media('<tablet') {
        left: 0 !important;
        z-index: 99;
    }
}
