.category-view {
    @include container-wide;
    @include grid-standard(1);
    position: relative;
    margin-bottom: 40px;
    padding-top: 32px;
    padding-bottom: 32px;

    @include media('>=tablet_portrait') {
        @include grid-standard(2);
        text-align: left;
    }

    &:before {
        content: "";
        background-color: $color__white;
        position: absolute;
        inset: 0 -50vw;
        z-index: -1;
    }

    .page-title-wrapper {
        align-self: end;
        width: 100%;
        max-width: 368px;
        justify-self: center;
        margin-top: 32px;

        @include media('>=tablet_portrait') {
            @include grid-column(1, 2);
            grid-row: 1;
        }
    }

    .category-description {
        @include text;
        margin-bottom: 32px;
        width: 100%;
        max-width: 368px;
        justify-self: center;

        @include media('>=tablet_portrait') {
            @include grid-column(1, 2);
            grid-row: 2;
        }

        * {
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    .category-image,
    .gallery-placeholder {
        grid-row: 1;

        @include media('>=tablet_portrait') {
            @include grid-column(1);
            grid-row: 1 / span 2;
        }

        img {
            width: 100%;
            aspect-ratio: 608 / 456;
            object-fit: cover;
            border-radius: 8px;
            overflow: hidden;
        }
    }

    .fotorama__nav-wrap {
        margin-bottom: 32px;
    }

    .fotorama__thumbs {
        border-radius: 8px;
        overflow: hidden;
    }

    .fotorama__thumb {
        background-color: transparent;
        object-fit: cover;
        opacity: .48;
        cursor: pointer;
        transition: 300ms linear opacity;

        img {
            width: 100%;
            height: 100%;
            max-width: none;
            max-height: none;
            object-fit: cover;
            aspect-ratio: 1;
        }
    }

    .fotorama__active .fotorama__thumb,
    .fotorama__thumb:hover {
        opacity: 1;
    }

    .fotorama__thumb-border {
        display: none;
    }

}
