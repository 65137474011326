.page-header {
    box-shadow: 0 0 16px rgba(61, 52, 47, 0.16);
    background: $color__off-white;
    position: relative;
    z-index: 1;

    .header.content {
        @include container-wide;
        height: 56px;
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 0;

        @include media('>=laptop') {
            height: 72px;
        }
    }

    .logo {
        height: 32px;
        order: 1;
        margin: 0;

        @include media('<tablet') {
            flex: 1;
        }

        @include media('>=laptop') {
            margin: 0 16px 0 0;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    .nav-sections {
        transition: opacity .3s;
        opacity: 1;
        order: 2;
        flex: 1;
    }

    &:has(.minisearch.active) .nav-sections {
        opacity: 0;
    }

    .block-search {
        order: 3;
    }

    .user-actions-wrapper {
        order: 4;


        .account-wrapper {
            order: 1;
        }

        .wishlist--button {
            order: 2;
        }

        .minicart-wrapper {
            order: 3;
        }

        .language {
            order: 4;
        }

    }

    .nav-toggle {
        order: 5;
    }

}
