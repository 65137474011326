.footer-bottom {
    position: relative;

    ul {
        list-style: none;
        display: flex;
        padding: 0;
    }

    li {
        margin: 0;

        &:not(:last-child) {
            margin-right: 8px;
        }

        a, span {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            background: $color__green;
            border-radius: 100%;
            align-items: center;
        }
    }
}
