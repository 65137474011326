.category-categories {
  @include grid-standard(2);

  //@include media('>=tablet_portrait') {
  //}

  @include media('>=tablet_portrait') {
    @include grid-standard(3);
  }

  &--subcategory {
    @include media('>=laptop') {
      @include grid-standard(4);
    }
  }

  &__category {
  }
}