
.seo,
.category-seo-attribute {
    @include container-wide;
    text-align: center;
    padding: 72px 0 88px;
    position: relative;
    margin-top: 40px;
    margin-bottom: 0;

    &:before {
        content: "";
        background-image: url(../images/seo-bg.webp);
        background-repeat: repeat-y !important;
        background-position: center 0 !important;
        position: absolute;
        inset: 0 -50vw;
        z-index: -1;
        pointer-events: none;
    }

    > div,
    &__content {
        //@include container-narrow;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }

    h2 {
        @include heading-h1;
        padding-top: 16px;
    }

    h3 {
        @include text;
        color: $color__black;
    }
}
