.newsletter-block {
    @include container-wide;
    margin-bottom: 0;
    padding-top: 88px;
    padding-bottom: 88px;
    background: $color__white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
        @include heading-h1;
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: center;

        @include media('<tablet') {
            flex-direction: column;
        }
    }


    #mc_embed_signup {
        max-width: 352px;
        margin: 32px auto 0;
    }

    .mc-field-group {
        strong {
            display: none;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            margin: 16px 0;
        }
    }

    input[type=submit] {
        @include button;
        @include button--contrast;
        width: 100%;
    }
}
