
$minicart__counter-background: $color__green !default;

.minicart-wrapper {
    position: relative;

    .action.showcart {
        display: flex !important;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin: 0;

        &:before {
            // added styling to critical/_header.scss
        }

        .counter.qty {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            line-height: 16px;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
            font-weight: normal;
            border-radius: 50%;
            margin: 0;
            padding: 0;
            background: $minicart__counter-background;
            color: $color__black;

            &.empty {
                display: none;
            }
        }

        .counter-number {
            text-shadow: none;
        }

        .counter-label {
            display: none;
        }
    }

    .ui-dialog {
        @include media('>=tablet_portrait') {
            top: calc(100% + 16px);
            right: 0;
            margin: 0;
        }

        &:after {
            @include media('>=tablet_portrait') {
                content: "";
                position: absolute;
                top: -8px;
                right: 12px;
                background: $color__white;
                width: 16px;
                height: 16px;
                transform: rotate(45deg);
                border-radius: 2px;
                border: none;
            }
        }

        .close {
            display: none;
        }
    }

    .text,
    .block-title {
        display: none;
    }

    ol {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .items-total {
        margin-bottom: 16px;

        .count {
            font-weight: bold;
        }
    }

    .subtotal {
        margin-bottom: 16px;

        .label {
            font-weight: bold;
        }
    }

    .actions {
        display: flex;
        justify-content: center;
    }

    .action.checkout,
    .action.viewcart {
        @include button;
    }

    .product.actions {
        display: flex;
        justify-content: space-between;
    }

    .product.options {
        display: none;
    }

    .qty {
        display: none;
    }

    .action.edit {
        display: none;
    }
}
