.hero {
    position: relative;
    margin-bottom: 32px;

    &:before {
        content: "";
        background-color: $color__white;
        position: absolute;
        inset: 0 -50vw;
        z-index: -1;
    }

    > *:not(:first-child) {
        display: none;
    }

    .pagebuilder-column-line {
        @include media('>=tablet') {
            gap: 32px;
        }
    }

    .pagebuilder-column:last-child {
        align-self: end;
        width: 100%;
        max-width: 368px;
        justify-self: center;
        margin: 32px auto;
    }

    .pagebuilder-poster-content {
        @include container-wide;
        position: relative;
        min-height: 624px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 120px;

        @include media('>=tablet') {
            padding-bottom: 0;
            align-items: flex-start;
        }

        > div {
            @include media('>=tablet') {
                width: 50%;
            }
        }
    }

    img {
        width: 100%;
        aspect-ratio: 608 / 456;
        object-fit: cover;
    }

    a {
        @include button;
        margin-top: 40px;
    }
}
