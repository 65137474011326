.products-grid {
    a {
        text-decoration: none !important;
        color: $color__black !important;
    }

    .product-items {
        @include grid-standard(1);
        padding: 0;

        @include media('>=tablet_portrait') {
            @include grid-standard(2);
        }

        @include media('>=tablet') {
            @include grid-standard(4);
        }
    }

    .product-item {
        position: relative;
        margin: 0 !important;
        width: auto;
        display: flex;
        background: $color__white;
        box-shadow: 0 0 0 1px rgba(180, 214, 199, 0.24);
        overflow: hidden;
        transition: box-shadow 300ms;

        &:hover {
            box-shadow: 0 0 16px rgba(180, 214, 199, 0.56);

            .product-item-read-more {
                opacity: 1;
            }
        }
    }

    .product-label {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 24px;
        background: $color__black;
        color: white;
        z-index: 10;
        padding: 8px;
        min-width: 96px;
        font-size: 16px;
        line-height: 1;

        &--special-price {
            right: 0;
            border-radius: 24px 0 0 24px;
        }
        &--good-price {
            left: 0;
            border-radius: 0 24px 24px 0;
            background-color: $color__green;
        }
    }

    .product-item-info {
        position: relative;
        border: none;
        width: 100%;
        display: flex;
        flex-direction: column;

        &:hover,
        &.active {
            box-shadow: none;
            margin: inherit;
            padding: 0;
            z-index: 9;
        }
    }

    .product-item-photo {
        position: relative;
        overflow: hidden;
        margin: 0;
    }

    .product-image-container {
        width: 100%;
        overflow: hidden;
    }

    .product-image-wrapper {
        height: auto;
    }

    .product-image-photo {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        width: calc(100% - 64px);
        height: auto !important;
        aspect-ratio: 256 / 168;
        margin: 32px auto 0;
    }

    //.product-item-actions {
    //    margin: 24px 0 0;
    //    display: flex;
    //    justify-content: center;
    //}

    .product-item-link {
        @include card-text;
        display: block;
        margin-bottom: 8px !important;
        position: static;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: 1;
        }
    }

    .product-item-name {
        text-align: center;
    }

    .actions-primary {
        display: block;
    }

    .product-item-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 16px 32px;
    }

    .product-item-sku {
        font-size: 12px;
        font-style: normal;
    }

    .minimal-price {
        .price-label {
            display: none;
        }
    }

    .price-box {
        @include price;
        text-align: center;
        margin-bottom: 16px;
        color: $color__black;
        flex: 1;
    }

    .price-box > .price-container,
    .price-box .special-price .price-container,
    .price-box .minimal-price .price-container,
    .stock.unavailable {
        display: block;
    }

    .stock.unavailable span {
        line-height: 48px !important;
    }

    .price-box .old-price {
        display: none;
    }

    .tocart {
        display: none;
    }

    .product-item-read-more {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        opacity: 0;
        transition: opacity 300ms;
        text-transform: uppercase;
        gap: 8px;
        margin-top: 16px;

        &:after {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background-size: contain;
            background-image: url('data:image/svg+xml; utf8, <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.333v9.334M12.667 8 8 12.667 3.333 8" stroke="currentColor" stroke-linecap="round"/></svg>');
            transform: rotate(-90deg);
        }
    }
}
