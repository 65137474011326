$text-style__heading-font: "Abhaya Libre", sans-serif !default;
$text-style__text-font: "brandon-grotesque", sans-serif !default;

$text-styles__heading-color: $color__blue !default;
$text-styles__text-color: rgba($color__black, .88) !default;
$text-styles__link-color: $color__black !default;

$text-styles__block-title-color: $color__blue !default;

$text-style__margin: 0 0 16px 0;

@mixin heading {
    font-family: $text-style__heading-font;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
    margin: $text-style__margin;
    color: $text-styles__heading-color;
    -webkit-font-smoothing: subpixel-antialiased;
    display: block;

    &:last-child {
        margin-bottom: 0;
    }
}

@mixin heading-h1 {
    @include heading;
    letter-spacing: 1px;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;

    @include media('>tablet_portrait') {
        font-size: 40px;
        line-height: 48px;
    }
}

@mixin heading-h2 {
    @include heading;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;


    @include media('>tablet_portrait') {
        font-size: 24px;
        line-height: 32px;
    }
}

@mixin heading-h3 {
    @include heading;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;

    @include media('>tablet_portrait') {
        font-size: 16px;
        line-height: 24px;
    }
}

@mixin text {
    font-family: $text-style__text-font;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    margin: $text-style__margin;
    -webkit-font-smoothing: subpixel-antialiased;
    color: $text-styles__text-color;

    &:last-child {
        margin-bottom: 0;
    }
}

@mixin text-link {
    @include text;
    position: relative;
    display: inline-block;
    color: $text-styles__link-color;

    &:hover {
        color: rgba($text-styles__link-color, .64);
        text-decoration: none;
    }
}

// TODO: replace?
@mixin block-title {
    @include heading-h3;
    color: $text-styles__block-title-color;
    margin-bottom: 24px;
}

@mixin card-text {
    @include text;
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
    letter-spacing: 4px;
}

@mixin price {
    @include text;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
}

@mixin accordion {
    @include text;
    display: block;
    font-size: 20px;
    line-height: 16px;
    font-weight: 300;
    letter-spacing: 2px;
    text-decoration: none;
}

@mixin label {
    @include text;
    font-size: 12px;
}



