.pager {

  &__items {
    margin: 40px 0 24px;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;
    min-width: 48px;

    a {
      @include button;
      color: $color__white;
      margin: 4px;
      min-width: 48px;
      background: $color__black;
    }

    &--first,
    &--last {
      display: none;
    }

    &.is-active,
    &:hover {
      a {
        background: $color__green;
      }
    }
  }
}
