.category-attribute {
  margin-bottom: 8px;

  &__title {
    font-family: $text-style__heading-font;
    font-size: 16px;
    color: $color__black;
    margin-bottom: 0;
  }

  &__value {
    color: $color__blue;
  }
}