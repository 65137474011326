$grid-gap: 32px !default;
$grid-gap-landscape-down: 24px !default;
$grid-gap-phone-only: 16px !default;

@mixin container() {
  max-width: 1120px;
  margin: 0 auto 32px;
  padding: 0 20px 0 19px;

  @include media('>=tablet_portrait') {
    padding-right: 40px;
    padding-left: 40px;
  }

  @include media('>=tablet') {
    padding-right: 44px;
    padding-left: 44px;
  }

  @include media('>=laptop') {
    padding-right: 128px;
    padding-left: 128px;
  }

  @include media('>=desktop') {
    padding-right: 160px;
    padding-left: 160px;
  }
}

@mixin container-wide() {
  @include container();
  max-width: 1440px; // over grid
  //padding-left: 0;
  //padding-right: 0;
}

@mixin container-narrow() {
  @include container();

  @include media('>=tablet') {
    max-width: calc(616px + 44px + 44px); // 8 col
  }

  @include media('>=laptop') {
    max-width: calc(672px + 128px + 128px); // 8 col
  }

  @include media('>=desktop') {
    max-width: calc(736px + 160px + 160px); // 8 col
  }
}

@mixin grid-standard($_columns: 12) {
  display: grid;
  grid-gap: $grid-gap;
  grid-template-columns: repeat($_columns, 1fr);

  //@if ($_columns == 12) {
  //  @include media('>=tablet_portrait', '<tablet') {
  //    grid-template-columns: repeat(8, 1fr); //Only 8 columns on tablets in portrait mode
  //  }
  //
  //  @include media('<tablet_portrait') {
  //    grid-template-columns: repeat(4, 1fr); //Only 4 columns on phones
  //  }
  //}

  @include media('<laptop') {
    grid-gap: $grid-gap-landscape-down;
  }

  @include media('<tablet_portrait') {
    grid-gap: $grid-gap-phone-only;
  }
}

@mixin grid-container($_columns: 12) {
  @include container();
  @include grid-standard($_columns);
}

@mixin grid-column($n, $start-col: auto) {
  grid-column: $start-col / span $n;
}

@mixin responsive-max-grid-columns {
  @include media('>=tablet', '<laptop') {
    @include grid-column(12);
  }

  @include media('>=tablet_portrait', '<tablet') {
    @include grid-column(8);
  }

  @include media('<tablet_portrait') {
    @include grid-column(4);
  }
}

@mixin tablet-portrait-down-max-grid-columns {
  @include media('>=tablet_portrait', '<tablet') {
    @include grid-column(8);
  }

  @include media('<tablet_portrait') {
    @include grid-column(4);
  }
}

@mixin override-responsive-grid-gap($grid-gap-value) {
  @include media('>=tablet', '<laptop') {
    grid-gap: $grid-gap-value;
  }

  @include media('>=tablet_portrait', '<tablet') {
    grid-gap: $grid-gap-value;
  }

  @include media('<tablet_portrait') {
    grid-gap: $grid-gap-value;
  }
}

@mixin columns-text($number-of-columns: 2) {
  columns: $number-of-columns;
  column-gap: $grid-gap;

  @include media('<laptop') {
    column-gap: $grid-gap-landscape-down;
  }

  @include media('<tablet_portrait') {
    columns: unset;
  }
}
