
.navigation > .ninjamenus.ninjamenus-desktop {
  margin: 0;
  position: static;

  .nav-item > a {
    font-size: 16px;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
  }

  > .magezon-builder {
    display: flex;
    justify-content: center;
    margin: 0 16px;
    white-space: nowrap;
  }

  .level0 {
    float: none;
    position: static;

    &:not(:last-child) {
      margin-right: 24px;

      @include media('>=desktop') {
        margin-right: 64px;
      }
    }

    > a {
      background: transparent !important;
      color: $color__blue !important;
      text-align: center;
      font-weight: normal;
      font-family: $text-style__heading-font;

      .caret {
        display: none !important;
      }
    }

    &:hover,
    &.active {
      > a {
        &, span {
          text-decoration: underline;
        }
      }
    }

    // level1
    > .item-submenu {
      @include container-wide;
      padding-top: 48px;
      padding-bottom: 48px;
      width: auto;
      position: absolute;
      left: 0;
      right: 0;
      background: $color__off-white;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);

      a {
        @include text;
        font-size: 12px;
        margin-bottom: 4px;
      }

      .inner-content {
        @include grid-standard(5);
        align-items: center;
      }

      .nav-item {
        display: block;

        > a {
          @include text;
          margin-bottom: 16px;

          &:before {
            content: none !important;
          }
        }
      }
    }
  }
}
