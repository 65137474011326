.header-promo-wrapper {
  @include container-wide;
  margin-bottom: 0;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 32px;
  isolation: isolate;

  @include media('<tablet') {
    // TODO: Move to CLS
    display: none;
  }

  * {
    font-size: 12px;
    line-height: 32px;
    color: $color__off-white !important;
  }

  &:before {
    content: "";
    position: absolute;
    left: -50vw;
    right: -50vw;
    top: 0;
    bottom: 0;
    background: $color__blue;
    z-index: -1;
  }
}
