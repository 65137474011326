
$footer__background: $color__off-white !default;

.page-footer {
    box-shadow: 0 0 16px rgba(61, 52, 47, 0.16);
    background: $footer__background;

    .footer.content {
        @include container-wide;
        display: flex;
        flex-direction: column;
        border-top: unset;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-bottom: 0;
        position: relative;

        @include media('<tablet') {
            text-align: center;
            align-items: center;
        }

        @include media('>=tablet') {
            padding-top: 56px;
            padding-bottom: 56px;
        }
    }

    .links {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;

        @include media('>=tablet') {
            @include grid-standard(1);
            grid-template-columns: 7fr 5fr;
        }

        img {
            margin: 0 auto 16px;
            height: 24px;

            @include media('>=tablet') {
                margin: 0 0 16px;
            }
        }

        p {
            margin-bottom: 8px;
        }
    }

    .ninjamenus {
        background: transparent !important;
        overflow-x: unset;
        border: none;
        margin-bottom: 24px;

        &.ninjamenus-mobile {
            .opener {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 56px;
                height: 56px;

                &:before {
                    content: "";
                    width: 24px;
                    height: 24px;
                    display: block;
                    mask-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    background: $color__black;
                    transition: transform 300ms;
                }
            }
        }

        &--ninjamenus-desktop {
            margin-top: 0;
        }

        .ninjamenus-toggle-active > .opener:before {
            transform: translate(-50%,-50%) rotate(180deg);
        }

        a {
            @include text;
            background: none !important;
            display: flex !important;
            color: $color__black !important;
            text-decoration: none;


            @include media('<tablet') {
                justify-content: center;
            }
        }

        .level0 > a {
            font-weight: bold;
            padding: 16px 0;
            margin: 0;
        }

        .item-submenu a {
            padding: 8px 0;
        }
    }

    .magezon-builder {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @include media('>=tablet') {
            flex-direction: row;
        }
    }
}
