.product.media {
    position: relative;
    margin-bottom: 16px;

    .fotorama__stage__frame .fotorama__img {
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-fit: cover;
    }

    //.fotorama__nav-wrap {
    //    display: none !important;
    //
    //    @include media('>=tablet_portrait') {
    //        display: block !important;
    //    }
    //}

    //.fotorama__nav--thumbs .fotorama__nav__frame {
    //    padding: 16px 0;
    //}

    .fotorama__thumb-border {
        display: none;
    }

    .fotorama__thumb {
        object-fit: cover;
        opacity: .48;
        cursor: pointer;
        transition: 300ms linear opacity;
    }

    .fotorama__active .fotorama__thumb,
    .fotorama__thumb:hover {
        opacity: 1;
    }

    .fotorama__stage.fotorama__shadows--right:after,
    .fotorama__nav.fotorama__shadows--right:after,
    .fotorama__stage.fotorama__shadows--left:before,
    .fotorama__nav.fotorama__shadows--left:before {
        opacity: .2;
    }

    .fotorama__nav--dots {
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
    }

    .fotorama__nav__frame--dot {
        width: 24px;
        height: 24px;

        .fotorama__dot {
            background: $color__green;
            border: none;
            width: 8px;
            height: 8px;
            opacity: .64;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &.fotorama__active .fotorama__dot {
            width: 12px;
            height: 12px;
            opacity: 1;
        }
    }
}
