.swatch-attribute {

    &.color {
        //
    }

    a {
        margin-bottom: 0;
    }

}

.swatch-attribute-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
        text-decoration: none;
    }
}

.swatch-option {
    font-weight: 500;
    width: 24px;
    height: 24px;
    mask-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.946 7.794c-.086 0-.172 0-.256.01a7.776 7.776 0 0 0-13.028.023 2.99 2.99 0 0 0-1.08 5.878 7.776 7.776 0 0 0 15.21-.053 2.99 2.99 0 0 0-.857-5.858h.01Z" fill="currentColor"/></svg>');
    mask-size: contain;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.swatch-input {
    display: none !important;
}
