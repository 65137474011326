.catalog-product-view {

    .catalog-product-top {
        @include container-wide;
        @include grid-standard(1);

        @include media('>=laptop') {
            @include grid-standard();
        }
    }

    .catalog-product-gallery {
        @include media('>=laptop') {
            @include grid-column(6);
        }
    }

    .catalog-product-aside {
        @include media('>=laptop') {
            @include grid-column(5, 8);
            margin-top: 56px;
        }
    }

    .usp {
        @include media('<tablet_portrait') {
            display: none !important;
        }
    }
}
