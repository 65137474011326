
$button__background: $color__green !default;
$button__text-color: $color__off-white !default;

$button__background--contrast: $color__blue !default;


@mixin button($background: $button__background) {
  @include text;
  color: $button__text-color;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  min-width: 170px;
  height: 40px;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity 300ms;
  text-decoration: none;
  position: relative;
  border: 1px solid transparent;
  background-color: $background;

  &:hover {
    opacity: .8;
  }
}

@mixin button--contrast() {
  background-color: $button__background--contrast;
}
@mixin button--white() {
  background-color: $color__white;
  color: $color__black;
}

@mixin button--small() {
  font-size: 12px;
  border-radius: 8px;
  font-weight: 400;
  height: 32px;
}

