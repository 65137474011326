
.navigation {
    @include media('<tablet') {
        background: $color__off-white;
    }

    .nav-item {
        display: inline-block;
        position: relative;
    }
}
