.products-table-wrapper {
  @include container-wide;
}

.products-table {

  th {
    color: $color__black;
    font-size: 12px;
    line-height: 16px;
    font-weight: 900;
    letter-spacing: 1px;
    padding: 12px;
    text-align: left;

    &:first-child {
      padding-left: 56px;
    }

    &:last-child {
      padding-right: 56px;
    }
  }

  &.small-only {
    max-width: 640px;
    margin: 0 auto 16px;
    border-radius: 8px;

    &:nth-child(odd) {
      background-color: rgba($color__green, 0.08);
    }
    &:nth-child(even) {
      background-color: $color__white;
    }

    td {
      padding: 12px;
      color: $color__blue;
    }

    .st-key {
      font-weight: bold;
    }
  }
}

.products-table-thead {

  &__sortable {
    color: $color__blue !important;
    cursor: pointer;

    &:after {
      display: inline-block;
      margin-left: 8px;

      /* downwards arrow */
      content: url('data:image/svg+xml; utf8, <svg width="8" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 1.667v4.666M6.333 4 4 6.333 1.667 4" stroke="%23052C7A" stroke-linecap="round"/></svg>');
    }

    &.sorted {
      color: $color__green !important;

      &:after {
        /* upwards arrow */
        content: url('data:image/svg+xml; utf8, <svg width="8" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6.333V1.667M1.667 4 4 1.667 6.333 4" stroke="%2380BD9C" stroke-linecap="round"/></svg>');
      }
    }

    &.sorted--reverse:after {
      transform: rotate(180deg);
    }
  }
}

.products-table-tbody {
  tr {
    position: relative;
  }

  tr:nth-child(odd) td {
    background: rgba($color__green, 0.08);

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  td {
    color: $color__blue;
    padding: 4px 12px;
    font-weight: 300;

    &:first-child {
      padding-left: 56px;
    }

    &:last-child {
      padding-right: 56px;
    }
  }

  &__order {
    width: 170px;
  }

  &__sku {
    white-space: nowrap;
  }

  &__category a {
    margin: 8px 0;
  }
}