
.navigation > .ninjamenus.ninjamenus-mobile {
    .nav-item {
        width: 100%;
        float: none;

        &:hover,
        &.active {
            a {
                color: $color__black;
                text-decoration: underline;
            }
        }

        > a {
            line-height: 1;
            padding: 16px 48px;
            border: none !important;
            text-decoration: none;
            margin: 0;
        }
    }

    .item-submenu {
        padding: 0;
    }

    .level0 {
        border: none !important;

        > a {
            @include text;
            color: $color__black;
            min-height: 88px;
            display: flex;
            align-items: center;
            padding: 32px 48px;
            text-decoration: none;
            margin-bottom: 0;
        }

        > .opener {
            top: 20px;
            right: 24px;
        }
    }

    .mgz-single-image-wrapper,
    img,
    h6,
    .subtitle {
        display: none !important;
    }

    .opener:before {
        content: "";
        width: 24px;
        height: 24px;
        display: block !important;
        background-image: url('data:image/svg+xml; utf8, <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.333v9.334M3.333 8h9.334" stroke="%23A2B8AD" stroke-linecap="round"/></svg>');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .ninjamenus-toggle-active > .opener:before {
        background-image: url('data:image/svg+xml; utf8, <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.333 8h9.334" stroke="%23A2B8AD" stroke-linecap="round"/></svg>');

    }
}
