.catalogsearch-result-index .page-main {
    .page-title-wrapper {
        @include container-wide;
        margin: 32px auto;
    }

    .columns {
        @include container-wide;
    }

    .toolbar {
        display: none;
    }

    .search.results .block {
        display: none;
    }

    .sidebar {
        display: none;
    }
}
