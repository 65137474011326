.cms-page-view {
    .page-title-wrapper {
        @include container-narrow;
        margin: 32px auto;
    }

    .columns {
        //@include container-narrow;
    }

    .hero,
    .usp,
    .category-categories,
    .two-column {
        @include container-wide;
    }

    .usp {
        position: relative;
        //overflow: hidden;

        &:before {
            content: "";
            background-color: $color__blue;
            position: absolute;
            inset: 0 -50vw;
            z-index: -1;
            pointer-events: none;
        }

        .pagebuilder-column-line {
            padding: 64px 0 80px;
        }
    }

    .cms-content {
        [data-content-type="heading"] {
            @include container-narrow;
            width: 100%;
            margin-bottom: 8px;
        }

        [data-content-type="text"] {
            @include container-narrow;
            width: 100%;
            margin-bottom: 56px;
        }


        [data-content-type="image"] {
            @include container-wide;
            margin-bottom: 56px;

            img {
                border-radius: 8px;
            }
        }
    }
}
